import React from "react";
import {Box, Button, Stack, Typography, IconButton} from "@mui/material";
import {Instagram, KeyboardArrowUpRounded} from "@mui/icons-material";
import {scroll} from "../functions/scroll";
import {IG_LINK, TIKTOK_LINK} from "../config";
import {FaTiktok} from "react-icons/fa";

export const Footer = () => {

    const year = new Date().getFullYear()

    return (
      <Box sx={{pt:10, pb:{xs:5, lg:10}, position:'relative', overflow:'hidden'}}>
          <Box sx={{width:'min(100rem, 100%)', margin:'auto', p:1, position:'relative', zIndex:2}}>
              <Box sx={{width:'17rem', display:{xs:'block', lg:'none'}, margin:'auto', pb:8}}>
                  <img alt={"logo white"} style={{width:'100%'}} src={require('../assets/images/logo-white.png')}/>
              </Box>
              <Box sx={{width:'100%', textAlign:'center'}}>
                  <KeyboardArrowUpRounded/>
              </Box>
              <Stack direction={{xs:'column-reverse', lg:'row'}} alignItems={'center'} justifyContent={{xs:'center', lg:'space-between'}} spacing={{xs:8, lg:2, xl:0}}>
                  <Stack direction={{xs:'column', md:'row'}} spacing={4}>
                      {/*<Button sx={{py: 1.5, width: '15rem'}} variant={'outlined'} color={'secondary'}
                               href={'poster.jpg'} download={'poster.jpg'}>download poster</Button>*/}
                      <Button sx={{py:1.5, width:'15rem'}} variant={'contained'} onClick={() => scroll('booking')}>book now</Button>
                  </Stack>
                  <Box sx={{textAlign:'center'}}>
                      <Button onClick={() => scroll('top')} sx={{py:1.5, width:'15rem'}} variant={'outlined'} color={'secondary'}>back to the top</Button>
                  </Box>
                  <Stack direction={{xs:'column', md:'row'}} sx={{opacity:0, display:{xs: 'none', md:'block'}}} spacing={4}>
                      {/*<Button sx={{py: 1.5, width: '15rem'}} variant={'outlined'}>download poster</Button>*/}
                      <Button sx={{py:1.5, width:'15rem'}} variant={'contained'} onClick={() => scroll('booking')}>book now</Button>
                  </Stack>
              </Stack>
              <Stack direction={'row'} alignItems={'center'} justifyContent={{xs:'center', md:'start'}} spacing={{xs: 2, md: 4}} sx={{opacity:0.58, pt: {xs:4, md: 6}}}>
                  <Box sx={{width:'17rem', display:{xs:'none', lg:'block'}}}>
                      <img alt={"logo white"} style={{width:'100%'}} src={require('../assets/images/logo-white.png')}/>
                  </Box>
                  <Typography fontFamily={'Public Sans'} variant={'caption'}>
                      © {year} ALL RIGHTS RESERVED
                  </Typography>
                  <IconButton href={IG_LINK} target={'_blank'} color={'secondary'}>
                      <Instagram/>
                  </IconButton>
                  <IconButton href={TIKTOK_LINK} target={'_blank'} color={'secondary'}>
                      <FaTiktok/>
                  </IconButton>
              </Stack>
          </Box>
      </Box>
    )
}
