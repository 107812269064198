import React from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {TicketCard} from "./Tickets/TicketCard";

const ticketMap = [
    {
        title: "TRAVEL\nPACK",
        cta: "Book now",
        link: "https://www.pantelleriaisland.it/the-island-festival.html",
        bkg: "card-1",
        services: [
            {
                icon: "pin",
                text: "3 nights accommodation in a 3 or 4-star dammuso resort with swimming pool"
            },
            {
                icon: "ticket",
                text: "Full festival ticket"
            },
            {
                icon: "car",
                text: "Car rent for 2 people"
            },
            {
                type: "optional"
            },
            {
                icon: "plane",
                text: "Direct flight from Milan to Pantelleria or from Palermo to Pantelleria"
            },
        ]
    },
    {
        title: "festival\nticket",
        cta: "Buy now",
        link: "https://link.dice.fm/n3a96c3a5ae9",
        bkg: "card-2",
        services: [
            {
                icon: "ticket",
                text: "3 days of music and experiences"
            },
            {
                type: "separator",
            },
            {
                icon: "ticket",
                text: "1 day of music and experiences"
            }
        ]
    }
]

export const Tickets = () => {

    return (
        <Box className={'tickets-section'} id={'booking'}>
            <Container sx={{py: 10}} maxWidth={'md'}>
                <Typography color={'#005E2E'} textTransform={'uppercase'} textAlign={'center'} variant={'h3'}
                            fontFamily={'Blur'}
                            fontWeight={{xs: 700, md: 500}} sx={{pb: 2}} lineHeight={0.9}>
                    BOOK NOW
                </Typography>
                <Typography color={'#005E2E'} textAlign={'center'} fontFamily={'Public Sans'}>
                    Choose what’s best for you
                </Typography>
                <Box sx={{width: 'min(100%, 58rem)', margin: 'auto'}}>
                    <Grid container sx={{pt: 8}} spacing={3} justifyContent={'stretch'} alignContent={'center'}>
                        {ticketMap.map((t, i) =>
                            <Grid item xs={12} md={6} key={i}>
                                <TicketCard key={t.title} ticket={t}/>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
