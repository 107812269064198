export const artists = [
    {
        "name": "SETH TROXLER",
        "date": "may 30",
        "description": "",
        "image": "seth-troxler",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/3JkLFcTej6tdwZoQT6Nx4B?si=Tbxg9HvwTg2g0P24Y0cyBg"
    },
    {
        "name": "GERD JANSON",
        "date": "june 1",
        "description": "",
        "image": "gerd-janson",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/4jLpm91Tyk2TRgv43bMfZO?si=JNcL6L-mRrOipwMEhCNxTw"
    },
    {
        "name": "TIGA",
        "date": "may 31",
        "description": "",
        "image": "tiga",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/5l9wiTZVfqQTfMDOt0HtwC?si=cXydTBlCSuuDw0kmTDMRGw"
    },
    {
        "name": "DARDUST live",
        "date": "may 31",
        "description": "",
        "image": "dardust",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/6JhUHne9H09NdkTI5E9GSt?si=oP6Nn0AtQFu6kPuzJIGNvw"
    },
    {
        "name": "BOSS DOMS",
        "date": "may 31",
        "description": "",
        "image": "boss-doms",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/57aMHpsX2SXTbLatmeEd6j?si=GK6afxYgR5qCfwLS7hbXdQ"
    },
    {
        "name": "MATISA",
        "date": "may 30",
        "description": "",
        "image": "matisa",
        "service":"spotify",
        "playlist": "https://open.spotify.com/artist/18YBSDKYgR6so2I94gqUQ9?si=uRhIfv3LSreP8ahC6g7o6g"
    }
]
