import React from "react";
import {Box, Button, Container, Grid, Typography} from "@mui/material";
import {hotels} from "../assets/data/hotels";
import {HotelCard} from "./Hotels/HotelCard";

export const Hotels = () => {
    return (
        <Box sx={{py: 10}}>
            <Typography textAlign={'center'} variant={'h3'} fontFamily={'Blur'} fontWeight={{xs: 600}}>
                ACCOMMODATION
            </Typography>
            <Box sx={{pt: 10, display: {xs: 'none', lg: 'block'}}}>
                <Container maxWidth={'xl'}>
                    <Grid container spacing={4} justifyContent={'center'}>
                        {hotels.map((h, i) =>
                            <Grid item xs={6} xl={4}>
                                <HotelCard hotel={h}/>
                            </Grid>
                        )}
                    </Grid>
                </Container>
                <Container maxWidth={'xl'} sx={{pt: 5}}>
                    <Box sx={{textAlign: 'center', width: '100%'}}>
                        <Button variant={'outlined'} color={'secondary'}
                                href={'https://www.pantelleriaisland.it/the-island-festival.html'}
                                target={'_blank'}>
                            AND MORE
                        </Button>
                    </Box>

                </Container>
            </Box>
            <Box sx={{pt: 8, display: {xs: 'block', lg: 'none'}}}>
                <Grid container component={Container} spacing={{xs: 6, md: 1}}>
                    {hotels.map(h =>
                        <Grid item xs={12} md={6} key={h.id}>
                            <HotelCard hotel={h}/>
                        </Grid>
                    )}
                </Grid>
                <Box sx={{textAlign: 'center', pt: 6, width: '100%'}}>
                    <Button variant={'outlined'} color={'secondary'}
                            href={'https://www.pantelleriaisland.it/the-island-festival.html'} target={'_blank'}>
                        AND MORE
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
