import React, {useRef, useState} from "react";
import {Box, Container, Modal, Typography, Stack, Grid, useMediaQuery, Button} from "@mui/material";
import experiences from '../assets/data/experiences.json'
import {ExperienceCard} from "./Experiences/ExperienceCard";
import {CardModal} from "./shared/CardModal";
import {islandTheme} from "../theme/islandTheme";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

const top = experiences.slice(0, 6)

export const Experiences = () => {
    const [open,setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [currentSlide, setCurrentSlide] = useState(0)

    const navigationNextRef = useRef(null)
    const navigationPrevRef = useRef(null)

    const handleOpen = (bool, i) => {
        setIndex(i)
        setOpen(bool)
    }

    return (
      <Box className={'experiences-section'} id={'experience'} sx={{py:10, position:'relative', zIndex:1000, background:'#FFF4E7'}}>
          <CardModal open={open} handleOpen={handleOpen} index={index} type={'experiences'}/>
          <Typography color={'#005E2E'} textAlign={'center'} variant={'h1'} fontFamily={'Blur'} fontWeight={{xs: 700, md: 500}} lineHeight={0.9} sx={{pb:2}}>
              EXPERIENCES
          </Typography>
          <Typography color={'#005E2E'} textAlign={'center'} fontFamily={'Public Sans'}>
              Wellness, Discovery, Nature, Tasting
          </Typography>
          <Box sx={{mt: 8, pb: 2, display: {xs: 'none', md: 'block'}, position:'relative'}}>
              <Box sx={{position:'absolute', left:0, top:0, height:'100%', width:'100%', cursor:'pointer'}}>
                  <Stack sx={{width:'100%', height:'86%', px:2}} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Box ref={navigationPrevRef}
                           sx={{width: '5rem', height: '5rem', color: islandTheme.palette.primary.main, zIndex: 99, opacity: currentSlide >0 ? 1 : 0}}>
                          <img src={require('../assets/images/arrow.png')}
                               style={{rotate: '180deg', width: '100%'}}/>
                      </Box>
                      <Box ref={navigationNextRef} sx={{width:'5rem',height:'5rem', color:islandTheme.palette.primary.main, zIndex:99}}>
                          <img style={{width:'100%'}} src={require('../assets/images/arrow.png')}/>
                      </Box>
                  </Stack>
              </Box>
              <Swiper
                  navigation={{
                      nextEl: navigationNextRef.current,
                      prevEl: navigationPrevRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                      swiper.params.navigation.nextEl = navigationNextRef.current;
                      swiper.params.navigation.prevEl = navigationPrevRef.current;
                  }}
                  modules={[Navigation]}
                  slidesPerView={3.1}
                  spaceBetween={0}
                  grabCursor={true}
                  onActiveIndexChange={(e) => setCurrentSlide(e.activeIndex)}
                  breakpoints={{
                      1200: {
                          slidesPerView: 3.1,
                      },
                      800: {
                          slidesPerView: 2.1,
                      }
                  }}
              >
                  {experiences.map((e, i) =>
                      <SwiperSlide key={i}>
                          <Box sx={{ml: 2}}>
                              <ExperienceCard content={e} handleOpen={handleOpen} index={i}/>
                          </Box>
                      </SwiperSlide>
                  )}
              </Swiper>
          </Box>
          <Grid container component={Container} spacing={{xs:6, md:1}} sx={{py: 8, display: {xs: 'block', md: 'none'}}}>
              {top.map((e,i) =>
                  <Grid item xs={12} md={4} key={e.name}>
                      <ExperienceCard content={e} handleOpen={handleOpen} index={i}/>
                  </Grid>
              )}
          </Grid>
          <Box sx={{pt: {xs:5, md:8}, pb:6, textAlign:'center'}}>
              <Button variant={'outlined'} onClick={() => handleOpen(true, 0)}>
                  SEE all experiences
              </Button>
          </Box>
      </Box>
    )
}
