import React from "react";
import {Box, Container, Grid, Typography, Stack, useMediaQuery} from "@mui/material";
import {islandTheme} from "../../theme/islandTheme";
import Marquee from "react-fast-marquee";

const others = [
    {
        day: 'MAY 30',
        artists: [
            'Baldo Cottone b2b Pithree',
            'Sebastian',
            'Nunzio Borino',
            'Panorama',
            'Seth Troxler',
            'Matisa'
        ]
    },
    {
        day: 'MAY 31',
        artists: [
            'Baldo Cottone b2b Pithree',
            'Tayga',
            'Ethan & Sweeng',
            'Dardust',
            'Baldo Cottone b2b Pithree',
            'Nunzio Borino',
            'Giuseppe Fava',
            'Tiga',
            'Boss Doms',
        ]
    },
    {
        day: 'JUNE 01',
        artists: [
            'Ethan & Sweeng',
            'Panorama',
            'Tayga',
            'Sebastian',
            'Gerd Janson',
            'Bugsy',
        ]
    }
]

export const OtherArtists = () => {
    const lg = useMediaQuery(islandTheme.breakpoints.down('lg'))

    return (
        <Box sx={{position: 'relative', width: '100%'}}>
            <Container maxWidth={'md'}>
                {others.map((o, index) =>
                    <Grid container key={o.day} alignItems={'center'} spacing={{xs: 2, lg: 10}} sx={{py: 1.5}}
                          justifyContent={index === 0 ? 'center' : index === 1 ? 'end' : 'start'}>
                        <Grid item xs={12} lg={2} sx={{textAlign: {xs: 'center', lg: 'left'}}}>
                            <Typography fontWeight={700} fontSize={'1.3rem'} color={islandTheme.palette.primary.main}>
                                {o.day}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Container maxWidth={{xs: 'sm', lg: 'md'}} sx={{p: 0}}>
                                <Box sx={{
                                    width: '100%',
                                    px: 0.5,
                                }}>
                                    <Marquee
                                        gradient={true}
                                        gradientColor={"rgba(0,94,46,1)"}
                                        gradientWidth={50}
                                        style={{
                                            overflow: "hidden",
                                            height: '100%',
                                            border: `1px solid ${islandTheme.palette.primary.main}`,
                                            borderRadius: '50rem',
                                        }}
                                        autoFill={true}
                                    >
                                        {o.artists.map((a, i) =>
                                            <Box key={a} sx={{height: '100%', py: 2.2}}>
                                                <Typography fontFamily={'Public Sans'} fontSize={'1.188rem'}
                                                            color={'primary'}
                                                            textTransform={'uppercase'} letterSpacing={1.4}>
                                                    {a}{index !== o.artists.length - 1 &&
                                                    <span style={{paddingInline: '1rem'}}>&#x25cf;</span>}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Marquee>
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                )}
            </Container>
            {!lg && <Box sx={{
                position: 'absolute',
                opacity: '0.06',
                zIndex: '0',
                width: '100%',
                top: '60%',
                transform: 'translate(0, -50%)'
            }}>
                <Box sx={{width: '100%', overflow: 'hidden'}}>
                    <Typography fontSize={'17.3vw'} fontWeight={700} sx={{marginLeft: '-0.6rem'}} fontFamily={'Blur'}>
                        FULL&nbsp;LINEUP
                    </Typography>
                </Box>
            </Box>}
        </Box>
    )
}
