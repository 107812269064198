import React from "react";
import {Box, Link, Typography} from "@mui/material";

export const ArtistCard = ({a, handleOpen, index}) => {

    return (
        <Box onClick={() => handleOpen(true, index)}>
            <Box sx={{
                width: '100%',
                position: 'relative',
                boxShadow: {xs: '0px 0px 15px 1px rgba(0,0,0,0.19)', md: 'none'}
            }}>
                {a.playlist &&
                    <Box className={'spotify-container'}>
                        <Box sx={{width:'100%', height:'100%', position:'relative'}} className={'colored'}>
                            <Box sx={{width:'100%', position:'absolute'}} className={'neutral'}>
                                <img className={'rotating'}
                                     alt={"Playlist text"}
                                     style={{width: '100%', margin: 'auto'}}
                                     src={require('../../assets/images/artists/playlist-text.png')}/>
                            </Box>
                            <Link href={a.playlist} target={'_blank'} underline={'none'}
                                  sx={{opacity: '1', width:'100%', height:'100%', position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', colorOverlay:"white"}}>
                                <Box sx={{width:'60%', height:'60%'}} className={'neutral'}>
                                    <img style={{width: '100%'}}
                                         alt={"Provider"}
                                         src={require(`../../assets/images/artists/${a.service}.png`)}/>
                                </Box>
                            </Link>
                        </Box>
                    </Box>
                }
                <img style={{width: '100%', borderRadius:'1rem'}}

                     alt={"Dj"}
                     src={require(`../../assets/images/artists/${a.image}.jpg`)}/>
            </Box>
            <Typography fontWeight={700} variant={'h4'} sx={{pt: 2}}>
                {a.name}
            </Typography>
            <Typography fontWeight={500} variant={'h6'} textTransform={'uppercase'}>
                {a.date}
            </Typography>
        </Box>
    )
}
