import React, {useRef, useState} from "react";
import {Box, Grid, Typography, Container, Stack} from "@mui/material";
import {islandTheme} from "../../theme/islandTheme";
import {Swiper, SwiperSlide} from "swiper/react";
import {ArtistCard} from "./ArtistCard";
import {artists} from '../../assets/data/artists.js'
import {Navigation} from "swiper";
import {CardModal} from "../shared/CardModal";

const artistsMap = artists

const top = artistsMap

export const TopArtists = () => {
    const navigationNextRef = useRef(null)
    const navigationPrevRef = useRef(null)
    const [index, setIndex] = useState(0)
    const [open,setOpen] = useState(false)

    const handleOpen = (bool, i) => {
        /*setIndex(i)
        setOpen(bool)*/
    }

    const artistsSection = () => {
        if(artists.length <=3 ) {
            return (
                <Grid container spacing={{xs:6, md:2}} sx={{width:'100%', px:2}}>
                    {top.map((a, i) =>
                        <Grid item xs={12} md={4} key={`${a.name}-${i}`}>
                            <ArtistCard a={a} handleOpen={handleOpen} index={i}/>
                        </Grid>
                    )}
                </Grid>
            )
        }

        return (
            <Swiper
                navigation={{
                    nextEl: navigationNextRef.current,
                    prevEl: navigationPrevRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                }}
                modules={[Navigation]}
                grabCursor={true}
                slidesPerView={3.1}
                spaceBetween={0}
                onActiveIndexChange={(e) => setIndex(e.activeIndex)}
                breakpoints={{
                    1200: {
                        slidesPerView: 3.1,
                    },
                    800: {
                        slidesPerView: 2.1,
                    }
                }}
            >
                {artistsMap.map((a, i) =>
                    <SwiperSlide key={i}>
                        <Box sx={{ml: 2}}>
                            <ArtistCard a={a} handleOpen={handleOpen} index={i}/>
                        </Box>
                    </SwiperSlide>
                )}
            </Swiper>
        )
    }

    return (
        <Box sx={{pt: 10, pb: {xs: 0, lg: 10}, zIndex: '1', position: 'relative'}}>
            <CardModal open={open} handleOpen={handleOpen} index={index} type={'artists'}/>
            <Typography textAlign={'center'} variant={'h1'} fontWeight={{xs: 700, md: 500}} fontFamily={'Blur'}>
                LINE-UP
            </Typography>
            <Typography textAlign={'center'} fontFamily={'Public Sans'}>
                Top Artists
            </Typography>
            <Box sx={{mt: 8, pb: 2, display: {xs: 'none', md: 'block'}, position:'relative'}}>
                {artists.length > 3 && <Box sx={{position: 'absolute', left: 0, top: 0, height: '100%', width: '100%', cursor: 'pointer'}}>
                    <Stack sx={{width: '100%', height: '90%', px: 2}} direction={'row'} justifyContent={'space-between'}
                           alignItems={'center'}>
                        <Box ref={navigationPrevRef}
                             sx={{
                                 width: '5rem',
                                 height: '5rem',
                                 color: islandTheme.palette.primary.main,
                                 zIndex: 99,
                                 opacity: index > 0 ? 1 : 0
                             }}>
                            <img src={require('../../assets/images/arrow.png')}
                                 style={{rotate: '180deg', width: '100%'}}/>
                        </Box>
                        <Box ref={navigationNextRef}
                             sx={{width: '5rem', height: '5rem', color: islandTheme.palette.primary.main, zIndex: 99}}>
                            <img style={{width: '100%'}} src={require('../../assets/images/arrow.png')}/>
                        </Box>
                    </Stack>
                </Box>}
                {artistsSection()}
            </Box>
            <Grid container component={Container} spacing={{xs:6, md:1}} sx={{py: 8, display: {xs: 'block', md: 'none'}}}>
                {top.map((a, i) =>
                    <Grid item xs={12} md={4} key={`${a.name}-${i}`}>
                        <ArtistCard a={a} handleOpen={handleOpen} index={i}/>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
