export const faq = [
  {
    "id": 1,
    "question": "Can I book a single room?",
    "answer": <span>Our travel packs usually include a double room. Contact our tour operator partner <a href={'https://www.pantelleriaisland.it/the-island-festival.html'} target={'_blank'}>here</a> to receive assistance and find the best solution for you.</span>
  },
  {
    "id": 2,
    "question": "How can I book my place for the festival experiences?",
    "answer": "We will send all travel packs and festival tickets holders a link to a web app dedicated to the booking of experiences."
  },
  {
    "id": 3,
    "question": "How early should I book my place for the festival experiences?",
    "answer": "Hurry Up! Once you receive the link, book your experiences as soon as possible because the capacity is different for each experience."
  },
  {
    "id": 4,
    "question": "What accommodation solutions are included in the travel packs?",
    "answer": "Depending on the purchased travel pack, different types of accommodation are included (ranging from 4/5 star resorts to 3/4 star hotels). Our tour operator partner will assist you in finding the best solution for you."
  },
  {
    "id": 5,
    "question": "I would like to buy a travel pack, but I won’t leave from Milan.",
    "answer": <span>Contact our tour operator partner <a href={'https://www.pantelleriaisland.it/the-island-festival.html'} target={'_blank'}>here</a> to get an ad hoc solution for you from wherever you need to leave.</span>
  },
  {
    "id": 6,
    "question": "I have already purchased my festival ticket on Dice, but I need an accommodation.",
    "answer": <span>Contact our tour operator partner <a href='https://www.pantelleriaisland.it/the-island-festival.html' target='_blank'>here</a> to receive assistance and find the best solution for you.</span>
  },
  {
    "id": 7,
    "question": "What is included with the festival ticket purchased on Dice?",
    "answer": "The festival ticket includes access to a a single festival night or to all 3 festival nights and to 2 experiences for daily tickets and 6 experiences for 3-days tickets."
  },
  {
    "id": 8,
    "question": "I want to reserve a table for the night, how do I request it?",
    "answer": "Send an e-mail to info@theislandfestival.net to receive information."
  },
  {
    "id": 9,
    "question": "How can I move around the island?",
    "answer": <span>Rent a car or a motorcycle by contacting our tour operator partner <a href={'https://www.pantelleriaisland.it/contatti-consorzio.html'} target={'_blank'}>here</a>. We will also provide shuttles to and from the festival headquarter.</span>
  }
]
